@font-face {
font-family: 'Cooper Hewitt Thin';
font-style: normal;
font-weight: normal;
src: local('Cooper Hewitt Thin'), url('./fonts/CooperHewitt-Thin.woff') format('woff');
}


@font-face {
font-family: 'Cooper Hewitt Thin Italic';
font-style: normal;
font-weight: normal;
src: local('Cooper Hewitt Thin Italic'), url('./fonts/CooperHewitt-ThinItalic.woff') format('woff');
}


@font-face {
font-family: 'Cooper Hewitt Light';
font-style: normal;
font-weight: normal;
src: local('Cooper Hewitt Light'), url('./fonts/CooperHewitt-Light.woff') format('woff');
}


@font-face {
font-family: 'Cooper Hewitt Light Italic';
font-style: normal;
font-weight: normal;
src: local('Cooper Hewitt Light Italic'), url('./fonts/CooperHewitt-LightItalic.woff') format('woff');
}


@font-face {
font-family: 'Cooper Hewitt Book';
font-style: normal;
font-weight: normal;
src: local('Cooper Hewitt Book'), url('./fonts/CooperHewitt-Book.woff') format('woff');
}


@font-face {
font-family: 'Cooper Hewitt Book Italic';
font-style: normal;
font-weight: normal;
src: local('Cooper Hewitt Book Italic'), url('./fonts/CooperHewitt-BookItalic.woff') format('woff');
}


@font-face {
font-family: 'Cooper Hewitt Medium';
font-style: normal;
font-weight: normal;
src: local('Cooper Hewitt Medium'), url('./fonts/CooperHewitt-Medium.woff') format('woff');
}


@font-face {
font-family: 'Cooper Hewitt Medium Italic';
font-style: normal;
font-weight: normal;
src: local('Cooper Hewitt Medium Italic'), url('./fonts/CooperHewitt-MediumItalic.woff') format('woff');
}


@font-face {
font-family: 'Cooper Hewitt Semibold';
font-style: normal;
font-weight: normal;
src: local('Cooper Hewitt Semibold'), url('./fonts/CooperHewitt-Semibold.woff') format('woff');
}


@font-face {
font-family: 'Cooper Hewitt Semibold Italic';
font-style: normal;
font-weight: normal;
src: local('Cooper Hewitt Semibold Italic'), url('./fonts/CooperHewitt-SemiboldItalic.woff') format('woff');
}


@font-face {
font-family: 'Cooper Hewitt Bold';
font-style: normal;
font-weight: normal;
src: local('Cooper Hewitt Bold'), url('./fonts/CooperHewitt-Bold.woff') format('woff');
}


@font-face {
font-family: 'Cooper Hewitt Bold Italic';
font-style: normal;
font-weight: normal;
src: local('Cooper Hewitt Bold Italic'), url('./fonts/CooperHewitt-BoldItalic.woff') format('woff');
}


@font-face {
font-family: 'Cooper Hewitt Heavy';
font-style: normal;
font-weight: normal;
src: local('Cooper Hewitt Heavy'), url('./fonts/CooperHewitt-Heavy.woff') format('woff');
}


@font-face {
font-family: 'Cooper Hewitt Heavy Italic';
font-style: normal;
font-weight: normal;
src: local('Cooper Hewitt Heavy Italic'), url('./fonts/CooperHewitt-HeavyItalic.woff') format('woff');
}

body {
  margin: 0;
  font-weight: normal;
  font-family: 'Cooper Hewitt Book',
  'Cooper Hewitt Bold' sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  font-size: 16px;
  max-width: 1920px;
  margin: auto;
}