$font-stack: 'Cooper Hewitt Book', sans-serif;
$primary-color: #C4C4C4;
$primary-color-darker: rgb(40, 58, 0);
$secondary-color: #EDF2B6;
$tertiary-color: #77A62A;
// SASS extend/inheritance @extend %xxxx-xxx
%flex-wrap-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end; 
}
%flex-wrap-normal {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

body {
  background-color: $primary-color;
}

.App {
  font-weight: normal;
  font-family: 'Cooper Hewitt Book';
  background-color: $primary-color;
  height: 100vh;
}
.container {
  background-color: $primary-color;
}

h1, h2, h3, h4 {
  font-family: 'Cooper Hewitt Bold';
}
h5, h6 {
  font-family: 'Cooper Hewitt SemiBold';
  margin: 0px;
}

.unbold-white {
  font-family: 'Cooper Hewitt Light';
  color: white;
}
.unbold {
  font-family: 'Cooper Hewitt Book';
}

.semibold {
  font-family: 'Cooper Hewitt Semibold';
}
.bold-green {
  font-family: 'Cooper Hewitt Bold';
  color: $tertiary-color;
}

.extra-large-title {
  font-family: 'Cooper Hewitt SemiBold';
  font-size: 2.5em;
}

img {
  display: block;
  margin: auto;
}

button{
  cursor: pointer;
  margin-right: 10px;
  border: none;
  padding: 15px 35px 10px 35px;
  font-family: 'Cooper Hewitt SemiBold';
  font-size: 1rem;
}

.side-nav {
  justify-content: flex-end;
  // width: 50%;
}

.arrow-btns {
  position: relative;
  margin-top: -400px;
  background-color: rgba(118, 166, 42, 0.15);
  width:100px;
  margin: 0;
  &:hover{
    background-color: rgb(100, 165, 0);
  }
  
}
#finger-emoji {
  margin: 3px;
  font-size: 1.5rem;
}

#linkedIn {
  color: $primary-color;
  font-family: 'Cooper Hewitt SemiBold';

}
p {
  font-family: 'Cooper Hewitt Book';
  font-size: 1.1rem;
  // line-height: 1.5rem;
}

.coloured-btn {
  background-color: $secondary-color;
}
.submit-btn {
  margin: 0;
  background-color: $secondary-color;
  // float: right;
  border-radius: 30px;
  font-weight: bold;
  &:hover {
    background-color: white;
    border: 1px solid $secondary-color;
    background-color: transparent;
    color: white;
  }
}

.nav-bar {
  @extend %flex-wrap-box;
  padding: 3% 5%;
  .place-logo{
    width: 120px;
  }
  .nav-options{
    padding: 0px;
    display: flex;
    // border: 1px solid white;
    ul {
      @extend %flex-wrap-box;
      margin: 0;
      li {
        margin: 0px 10px;
        color: $secondary-color;
        list-style: none;
        border-bottom: 2px solid white;
        &:hover, &:active {
          border-bottom: 8px solid white;
        }
      }
    }
  }
}

// .contact-page-video {
//   width: 80%;
//   height: 45vh;
//   overflow: hidden;
//   margin: auto;
//   margin-bottom: -3%;
// }

// video {
//   margin-bottom: -6px;
// }

a {
  color: $secondary-color;
  text-decoration: none;
  padding-bottom: 3px;
}

.landing-intro {
  justify-content: space-between;
  max-height: 100vh;
  .content {
    padding: 0 5%;
    display: flex;
    align-self: center;
    justify-content: center;
    position: relative;
    height: 80vh;
    .intro-welcome-animation {
      display: flex;
      align-items: center;
      p {
        font-size: 72px;
        font-family: 'Cooper Hewitt Light';
        color: $secondary-color;
        line-height: 0;
      }
      #faux-cursor {
        width: 2px;
        height: 80px;
        background: $secondary-color;
        margin: 0 8px;
      }
    }
    .intro-self {
      display: flex;
      align-items: center;
      div {
        font-size: 18px;
        color: white;
        #get-in-touch {
          font-family: "Cooper Hewitt Bold";
          // background: $secondary-color;
          position: relative;
          a {
            color: white;
          }
          #animated-bar {
            z-index: -1;
            position: absolute;
            margin-top: -15px;
            background:#77A62A;
            animation: .2s linear 0s 1 normal forwards running highlight;
            animation-delay: 4s;
            @keyframes highlight {
              0% {
                width: 0.01px;
                height: 10px;
              }
              100% {
                width: 110px;
                height: 10px;
              }
            }
          }
        }
      }
    }
  }
}
.animate-intro {
  animation:2s linear 2s 1 normal forwards intro-text;
  opacity: 0;
  @keyframes intro-text {
    0% {
      // transition: opacity 0.3s linear 2s;  
      opacity: 0;
    }
    100%{
      opacity: 1;
    }
  }
  .bar {
    height: 26px;
    background-color: $secondary-color;
    position: absolute;
    z-index: 7;
    color: $secondary-color;
    transform: rotate3d(0, -5, -5, -180deg);
  }
}

// animated bar
div {
  #line-one {
    display: flex;
    position: relative;
    color: white;
  }
  #line-two {
    position: relative;
  }
  #line-three {
    position: relative;
  }
}
#overlap {
    position: relative;
    z-index: 3;
    margin-bottom: -100px;
  .bar {
    width: auto;
    height: 24px;
    margin-top: 1rem;
    background-color: #77A62A;
  }
}



.background-deco-bar {
  background-color: $primary-color;
  mix-blend-mode: multiply;
  margin-right: -30px;
  margin-left: 5%;
  height: 305px;
}
.alternative-style {
  height: 450px;
}
.about-page {
  justify-content: space-between;
  position: relative;
  margin: auto;
  margin-top: -250px;
  width: 90%;
  .sidebar {
    text-align: right;
    width: 45%;
    margin-left: -10%;
  }
  .profile-photo{
    // background-color: white;
  }
  .about-me {
    width: 55%;
    padding: 50px;
    background-color: white;
    ul {
      // padding: 0;
      // list-style-position: inside;
      padding-inline-start: 15px;
      li {
        font-family: 'Cooper Hewitt SemiBold';
      }
    }
    span {
      font-family: 'Cooper Hewitt Book';
      margin: 10px;
      font-size: 0.8rem;
    }
    .job-title {
      margin-block-start: 0.3em;
      margin-block-end: 1.5em;
      font-size: 0.9rem;
      font-family: 'Cooper Hewitt Book Italic';
    }
  }
}


.wrapper {
  display: flex;
  flex-flow: wrap;
  .form-message {
     margin-left: 5%;
     margin-right: -30px;
    text-align: center;
    position: relative;
    background-color: $primary-color;
    mix-blend-mode: screen;
    padding: 3% 2%;
    width: 100%;
    p {
      color: white;
    }
  }
  .contact-form {
    background-color: #080b02;
    @extend %flex-wrap-normal;
    align-items: center;
    width: 65%;
    margin: auto;
    // position: relative;
    margin-top: 35px;
    padding: 50px 0;
    form {
      margin: auto;
      position: relative;
      z-index: 3;
      color: white;
      width: 80%;
      .form-first-row {
        @extend %flex-wrap-normal;
        width: 100%;  
      }
      label {
        width: 100%;
      }
      input {
        font-size: 1.1rem;
        margin-bottom: 20px;
        border: none;
        padding: 1%;
        width: 98%;
        height: 30px;
        &:focus {
          background-color: salmon;
          color: white;
        }
      }
      textarea {
        font-family: sans-serif;
        border: none;
        margin-bottom: 20px;
        font-size: 1.1rem;
        padding: 2%;
        resize: none;
        width: 96%;
      }
    }
  }
}

.blend {
  background-color: $primary-color-darker;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  mix-blend-mode: multiply;
}


section {
  padding-top: 100px;
}

.showcase {
  background-color: white;
  width: 100%;
  .project-name {
    font-size: 2rem;
  }
  .project-image-large {
    width: 100%;
  }
  .project-info-full {
    padding: 5%;
    width: 100%;
    justify-content: space-between;
  }
  .project-background {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    .summary {
      width: 65%;
      // line-height: 1.6rem;
    }
    .deliveries {
      width: 25%;
      ul {
        padding: 0;
        list-style-position: inside;
        font-size: 0.8rem;
      }
    }
  }
}

footer{
  border-top: 1px solid $secondary-color;
  position:absolute;
  bottom:0;
  left: 1.5%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  font-size: 0.8em;
  color: rgb(0, 0, 0);
  padding: 1%;
  width: 95%;
  #copyright{
    color: white;
    opacity: 5;
    transition: .5s;
  }
}

// Mobile
@media screen and (max-width: 600px) and (min-width: 250px){
  .container {
  border-right: white solid 30px;
  border-bottom: white solid 30px;
  }
  button{
    font-size: .8rem;
    margin: 8px 0 0 0;
  }
  .landing-intro {
    .profile-video {
      width: 100%;
    }
    .intro-body-content {
      width: 100%;
      .content {
        padding: 0 6% !important;
        text-align: center;
        width: 100%;
        .extra-large-title {
          font-family: 'Cooper Hewitt Bold';
          font-size: 1.2rem;
        }
        h2 {
          font-size: .9rem;
        }
      }
    }
  }
  .nav-bar {
    padding: 10% 5% 5% 5%;
    .place-logo{
      width: 120px;
    }
    .nav-options{
      width: 100%;
      margin-top: 50px;
      justify-content: flex-end;
      ul {
        width: 100%;
        padding: 0;
        li {
          font-size: 0.8rem;
          border-top: 1px solid white;
          padding: 8px 1px;
          color: white;
          list-style: none;
        }
      }
    }
  }
  .background-deco-bar {
    margin-right: 0px;
  }
  .about-page {
    .about-me {
    padding: 15px;
    }
    .resume p {
      font-size: .8rem;
      line-height: 1.1rem;
    }
  }
  .showcase {
    width: 100%;
    .project-info-full {
      padding: 5%;
      width: 100%;
    }
    .project-name {
      font-size: 1.5rem;
    }
    .project-background {
      display: flex;
      flex-flow: row wrap;
      .summary {
        width: 100%;
        // line-height: 1.6rem;
        .project-summary{
          font-size: 0.8rem;
          line-height: 1.1rem;
        }
      }
      .deliveries {
        display: flex;
        justify-content: space-between;
        width: 100%;
        .column {
          width: 45%;
        }
        ul {
          padding: 0;
          list-style-position: inside;
          font-size: 0.7rem;
        }
      }
    }
  }
  .wrapper {
    .form-message {
      padding: 3% 0% !important;
      h3 {
        padding: 0 3%;
        font-size: small;
      }
      .contact-form {
        padding: 10%;
        width: auto;
        form {
          color: white;
          width: 100%;
        }
        .blend {
          z-index: 2;
          background-color: $primary-color;
          mix-blend-mode: multiply;
        }
      }
    }
  }
  .side-nav{
    width: 100%;
  }
  .reverse {
    flex-flow: row;
  }
  // footer {
  //   font-size: 0.6rem;
  //   height: 200px;
  // }
}

/* When the width is between 320px and 900px */
@media screen and (max-width: 1248px) and (min-width: 320px) {
  .container {
    border: none; 
  }
  
  .landing-intro {
    .profile-video {
      width: 100%;
    }
    .intro-body-content {
      width: 100%;
      margin: -20px 0;
      .content {
        padding: 0 15%;
        text-align: center;
        width: 100%;
      }
    }
  }
  .wrapper {
    .contact-page-video {
      height: auto;
    }
    .form-message {
      margin: 0 5%;
      text-align: center;
      padding: 10%;
      width: 100%;
      height: fit-content;
      p {
        color: white;
        isolation: isolate;
      }
    }
    .contact-form {
      position: revert;
      padding: 5% 10%;
      margin-top: 20px;
      margin-right: 0px;
      width: auto;
      form {
        color: white;
        width: 100%;
        min-height: 50vh;
      }
      .blend {
        z-index: 2;
        background-color: $primary-color;
        mix-blend-mode: multiply;
      }
    }
  }
  .about-page{
    margin: auto;
    margin-top: -260px;
    width: 90%;
    .sidebar {
      text-align: right;
      width: 100%;
      margin-left: -10%;
    }
    .profile-photo{
      height: max-content;
      background-color: white;
    }
    .about-me {
      width: 100%;
      padding: 20px;
      background-color: white;
      span {
        font-size: .8rem;
      }
      .job-title {
        // margin-block-start: 0.3em;
        // margin-block-end: 1.5em;
        font-size: 0.8rem;
        line-height: 1rem;
      }
      .work-experience {
        font-size: .8rem;
      }
    }
  }
}